import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function Footer() {
    return (
        <Container className="bg-black" fluid>


        </Container>
    )

}

export default Footer;

